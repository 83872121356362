import { MouseEvent } from 'react';
import { gradientBackground } from '@utils/utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@states/store';
import settingsService from '@services/settingsService';
import {
  setSettingsPreset,
  setIsSettingsPresetLoaded,
  updateSavedSettings,
} from '@states/slices/settingsSlice';
import { setCurrentSkin } from '@states/slices/skinsSlice';

function SettingsCheck() {
  const dispatch = useDispatch();
  const { currentLanguage, color1, color2, settingsPreset } = useSelector(
    (state: RootState) => state.settings
  );

  async function loadSettingsPreset(e: MouseEvent) {
    e.preventDefault();
    try {
      const savedSettings = await settingsService.fetchSavedSettings(
        settingsPreset
      );
      if (!savedSettings) {
        if (settingsPreset === 'insait') {
          dispatch(setIsSettingsPresetLoaded(true));
          toast.success(currentLanguage.settings.response.default);
          return;
        }
        toast.error(currentLanguage.settings.response.error);
        return;
      }
      dispatch(setIsSettingsPresetLoaded(true));
      dispatch(updateSavedSettings(savedSettings));
      dispatch(setCurrentSkin(savedSettings.skinName));
      toast.success(currentLanguage.settings.response.success);
    } catch (error) {
      toast.error(currentLanguage.settings.response.error);
    }
  }

  return (
    <>
      <div className='login-wrapper'>
        <div className='login-text'>
          <h1 className='login-heading' style={{ color: color1 }}>
            {currentLanguage.settings.title}
          </h1>
          <p className='login-disc'>{currentLanguage.settings.description}</p>
          <form className='login-form'>
            <div className='input-box'>
              <input
                id='new-setting'
                placeholder={currentLanguage.settings.placeholder}
                value={settingsPreset}
                onChange={(e) => dispatch(setSettingsPreset(e.target.value))}
                autoComplete='settings'
              />
            </div>
            <button
              className='btn'
              onClick={(e) => loadSettingsPreset(e)}
              disabled={!settingsPreset}
              style={{
                background: gradientBackground(color1, color2),
              }}
            >
              {' '}
              {currentLanguage.settings.button}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
export default SettingsCheck;
