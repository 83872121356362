import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import conversationService from '@services/conversationService';
import { restartChat } from '@states/slices/chatbotSlice';
import { setShowNewChatSuggestion } from '@states/slices/chatbotSlice';
import { toast } from 'react-toastify';
import './NewChatSuggestion.scss';

function NewChatSuggestion() {
  const { currentConversation } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { currentLanguage, isFreemiumModeEnabled } = useSelector(
    (state: RootState) => state.settings
  );
  const dispatch = useDispatch();

  async function handleNewChat() {
    await conversationService.setConversationAsInactive(currentConversation);
    dispatch(restartChat());
    toast.info(currentLanguage.restart.new_chat);
    dispatch(setShowNewChatSuggestion(false));
  }

  return (
    <>
      <div
        className={`new-chat-wrapper ${
          isFreemiumModeEnabled ? 'with-watermark' : ''
        }`}
      >
        <h2>{currentLanguage.new_chat.request}</h2>
        <button onClick={handleNewChat} className='new-chat-btn'>
          {currentLanguage.new_chat.button}
        </button>
      </div>
    </>
  );
}

export default NewChatSuggestion;
