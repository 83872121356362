import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
  ChatbotState,
  ChatLanguage,
  ChatMessage,
  ChatStatus,
  ChatWindowStatus,
  Feedback,
} from '@utils/types';
import { toast } from 'react-toastify';
import { RootState } from '../store';
import { RESPONSE_STREAMING_ENABLED } from '@/utils/constants';

const initialState: ChatbotState = {
  openChatWindow: 'CLOSED',
  showSettings: false,
  chatHistory: [],
  suggestedQuestions: [],
  frequentlyAskedQuestions: [],
  chatStatus: 'ACTIVE',
  currentUser: '',
  currentConversation: '',
  isFirstMessageSent: true,
  isWaitingForBotReply: false,
  isValidUser: false,
  latestResponse: {
    id: '',
    pov: 'bot',
    text: '',
    file: '',
    time: new Date().toISOString(),
    rating: null,
  },
  finalBotReply: null,
  userInput: '',
  isQuestionInput: false,
  isStreamFinished: true,
  isInputDisabled: false,
  isInputSent: false,
  isFileSent: false,
  feedbackData: {
    userFeedback: '',
    userRating: null,
    submitted: false,
  },
  showFeedbackSuggestion: false,
  showNewChatSuggestion: false,
  displayChatbot: false,
  isUploadEnabled: false,
  savedCookies: '',
  idleTimeout: null,
  showTransferChat: false,
  iframeParentUrl: null
};

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    toggleOpenChatWindow: (state) => {
      state.openChatWindow =
        state.openChatWindow === 'OPEN' ? 'CLOSED' : 'OPEN';
    },
    setOpenChatWindow: (state, action: PayloadAction<ChatWindowStatus>) => {
      state.openChatWindow = action.payload;
    },
    setWaitingForBotReply: (state, action: PayloadAction<boolean>) => {
      state.isWaitingForBotReply = action.payload;
    },
    setShowSettings: (state, action: PayloadAction<boolean>) => {
      state.showSettings = action.payload;
    },
    setFirstMessagSent: (state, action: PayloadAction<boolean>) => {
      state.isFirstMessageSent = action.payload;
    },
    setIsValidUser: (state, action: PayloadAction<boolean>) => {
      state.isValidUser = action.payload;
    },
    setChatHistory: (state, action: PayloadAction<ChatMessage[]>) => {
      state.chatHistory = action.payload;
    },
    setChatStatus: (state, action: PayloadAction<ChatStatus>) => {
      state.chatStatus = action.payload;
    },
    setSuggestedQuestions: (state, action: PayloadAction<string[]>) => {
      state.suggestedQuestions = action.payload;
    },
    setFrequentlyAskedQuestions: (state, action: PayloadAction<string[]>) => {
      state.frequentlyAskedQuestions = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<string>) => {
      state.currentUser = action.payload;
    },
    setCurrentConversation: (state, action: PayloadAction<string>) => {
      state.currentConversation = action.payload;
    },
    setIsStreamFinished: (state, action: PayloadAction<boolean>) => {
      state.isStreamFinished = action.payload;
    },
    setIsInputDisabled: (state, action: PayloadAction<boolean>) => {
      state.isInputDisabled = action.payload;
    },
    setUserInput: (state, action: PayloadAction<string>) => {
      state.userInput = action.payload;
    },
    setIsInputSent: (state, action: PayloadAction<boolean>) => {
      state.isInputSent = action.payload;
    },
    setIsQuestionInput: (state, action: PayloadAction<boolean>) => {
      state.isQuestionInput = action.payload;
    },
    setIsFileSent: (state, action: PayloadAction<boolean>) => {
      state.isFileSent = action.payload;
    },
    setLatestResponse: (state, action: PayloadAction<ChatMessage>) => {
      state.latestResponse = action.payload;
    },
    setLatestResponseId: (state, action: PayloadAction<string>) => {
      state.latestResponse = {
        ...state.latestResponse,
        id: action.payload,
      };
    },
    addToLatestResponse: (state, action: PayloadAction<ChatMessage>) => {
      state.latestResponse = {
        ...state.latestResponse,
        text: state.latestResponse.text + action.payload.text,
        time: action.payload.time,
        id: action.payload.id ? action.payload.id : '',
      };
    },
    resetLatestResponse: (state) => {
      state.latestResponse = {
        ...state.latestResponse,
        text: '',
      };
      state.isStreamFinished = true;
    },
    setFinalBotReply: (state, action: PayloadAction<ChatMessage | null>) => {
      state.finalBotReply = action.payload;
    },
    restartChat: (state) => {
      state.currentConversation = uuidv4();
      state.chatHistory = [];
      state.isFirstMessageSent = false;
      state.isWaitingForBotReply = false;
      state.showFeedbackSuggestion = false;
      state.showNewChatSuggestion = false;
      state.feedbackData = { userFeedback: '', userRating: null };
      state.chatStatus = 'ACTIVE';
    },
    restartChatWithNewUser: (state, action: PayloadAction<ChatLanguage>) => {
      const language: ChatLanguage = action.payload;
      localStorage.removeItem('currentUser');
      state.currentUser = '';
      state.currentConversation = '';
      state.chatHistory = [];
      state.suggestedQuestions = [];
      state.frequentlyAskedQuestions = [];
      state.isFirstMessageSent = false;
      state.isWaitingForBotReply = false;
      state.showFeedbackSuggestion = false;
      state.showNewChatSuggestion = false;
      state.feedbackData = { userFeedback: '', userRating: null };
      state.chatStatus = 'ACTIVE';
      toast.info(language.restart.new_user);
    },
    setFeedbackData: (state, action: PayloadAction<Feedback>) => {
      state.feedbackData = action.payload;
    },
    setShowFeedbackSuggestion: (state, action: PayloadAction<boolean>) => {
      state.showFeedbackSuggestion = action.payload;
      state.feedbackData.submitted = !action.payload;
    },
    setShowNewChatSuggestion: (state, action: PayloadAction<boolean>) => {
      state.showNewChatSuggestion = action.payload;
    },
    handleSuggestNewChat: (state) => {
      state.showFeedbackSuggestion = false;
      state.showNewChatSuggestion = true;
    },
    setDisplayChatbot: (state, action: PayloadAction<boolean>) => {
      state.displayChatbot = action.payload;
    },
    setIsUploadEnabled: (state, action: PayloadAction<boolean>) => {
      state.isUploadEnabled = action.payload;
    },
    setSavedCookies: (state, action: PayloadAction<string>) => {
      state.savedCookies = action.payload;
    },
    setIdleTimeout: (state, action: PayloadAction<number | null>) => {
      state.idleTimeout = action.payload;
    },
    setShowTransferChat: (state, action: PayloadAction<boolean>) => {
      state.showTransferChat = action.payload;
    },
    setIframeParentURL: (state, action: PayloadAction<string | null>) => {
      state.iframeParentUrl = action.payload
    }
  },
});

export const {
  toggleOpenChatWindow,
  setOpenChatWindow,
  setWaitingForBotReply,
  setShowSettings,
  setFirstMessagSent,
  setIsValidUser,
  setChatHistory,
  setChatStatus,
  setSuggestedQuestions,
  setFrequentlyAskedQuestions,
  setCurrentUser,
  setCurrentConversation,
  setIsStreamFinished,
  restartChat,
  restartChatWithNewUser,
  setLatestResponse,
  addToLatestResponse,
  setLatestResponseId,
  resetLatestResponse,
  setFinalBotReply,
  setUserInput,
  setIsQuestionInput,
  setIsInputSent,
  setIsFileSent,
  setFeedbackData,
  setShowFeedbackSuggestion,
  setIsInputDisabled,
  setShowNewChatSuggestion,
  handleSuggestNewChat,
  setDisplayChatbot,
  setIsUploadEnabled,
  setSavedCookies,
  setIdleTimeout,
  setShowTransferChat,
  setIframeParentURL
} = chatbotSlice.actions;

export const isStreamingWaitingInProgress = createSelector(
  (state: RootState) => state.chatbot,
  ({ isInputDisabled, isWaitingForBotReply, isStreamFinished }) =>
    RESPONSE_STREAMING_ENABLED &&
    (isInputDisabled || isWaitingForBotReply || !isStreamFinished)
);

export default chatbotSlice.reducer;
