import { useState, useEffect } from 'react';
import utilsService from '@services/utilsService';
import { PdfPreviewProps } from './PdfPreview.type';
import './PdfPreview.scss';
import { LOCAL_FILE_STORAGE_ENABLED } from '@utils/constants';


const PdfPreview = ({
  url,
  thumbnail,
}: PdfPreviewProps) => {
  const [pdfFile, setPdfFile] = useState<string | null>(null);

  useEffect(() => {
    async function fetchPdf() {
      try {
        const pdfData = await utilsService.fetchPdf(url);
        setPdfFile(pdfData);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    }

    if (LOCAL_FILE_STORAGE_ENABLED) {
      setPdfFile(url);
    } else {
      fetchPdf();
    }
  }, [url]);

  if (!pdfFile) {
    return <div>Loading PDF...</div>;
  }

  return (
    <>
      <iframe
        src={pdfFile}
        width='100%'
        height='100%'
        className={thumbnail ? 'thumbnail' : 'pdf-preview'}
      >
        This browser does not support PDFs. Please download the PDF to view it:{' '}
        <a href={url}>Download PDF</a>.
      </iframe>
    </>
  );
};

export default PdfPreview;
