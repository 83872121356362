import { useEffect, useState } from 'react';

function useViewPortChange() {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  
  useEffect(() => {

    function updateViewPortHeight (viewPortHeight: number) {

      document.documentElement.style.setProperty('--viewport-height', `${viewPortHeight}px`);
      setIsKeyboardOpen(viewPortHeight < window.innerHeight);
    }
    
    function handleViewPortChange () {
      const viewPortHeight = window.visualViewport?.height;
      if (!viewPortHeight) return;

      updateViewPortHeight(viewPortHeight);
    };

    function handleEmbeddedMessage(event: MessageEvent) {
      if (event.data.type === 'SET_VIEWPORT_HEIGHT' && event.data.height) {
        const viewPortHeight = event.data.height;
        updateViewPortHeight(viewPortHeight)
      }
    }

    window.addEventListener('message', handleEmbeddedMessage);

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleViewPortChange);
    }

    return () => {
      window.removeEventListener('message', handleEmbeddedMessage);
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleViewPortChange);
      }
    };
  }, []);

  return { isKeyboardOpen };
}

export default useViewPortChange;
