import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/states/store';
import ChatBottomContainer from '../ChatBottom/ChatBottomContainer';
import { setOpenChatWindow } from '@states/slices/chatbotSlice';
import MessageItem from '../MessageItem';
import { ChatMessage, ChatWindowStatus } from '@/utils/types';
import './ChatPreview.scss';
import chatbotService from '@/services/chatbotService';

function ChatPreview() {
  const dispatch = useDispatch();
  const { botName, languageSelection, productSelection, botVariantSelection } =
    useSelector((state: RootState) => state.settings);

  const [previewMessage, setPreviewMessage] = useState<ChatMessage | null>(
    null
  );

  useEffect(() => {
    async function handlePrewiewMessage() {
      const botPreviewMessage = await chatbotService.fetchPreviewMessage(
        languageSelection,
        productSelection,
        botVariantSelection
      );

      setPreviewMessage({
        id: 'preview-message-id',
        pov: 'bot',
        text: botPreviewMessage,
        time: '',
      });
    }

    if (!previewMessage) handlePrewiewMessage();
  }, [previewMessage]);

  function handleChatWindowState(state: ChatWindowStatus) {
    dispatch(setOpenChatWindow(state));
  }

  return previewMessage ? (
    <div className='chat-preview-container'>
      <span
        className='chat-preview-close-btn'
        onClick={() => handleChatWindowState('CLOSED')}
      >
        &times;
      </span>
      <div className='chat-preview-inner'>
        <MessageItem
          message={previewMessage}
          id={'preview-message-id'}
          rating={null}
          title={botName}
        />

        <div
          className='chat-bottom-container'
          onClick={() => handleChatWindowState('OPEN')}
        >
          <ChatBottomContainer />
        </div>
      </div>
    </div>
  ) : null;
}

export default ChatPreview;
