import { useEffect, useRef } from 'react';
import companyService from '@services/companyService';
import { useDispatch } from 'react-redux';
import {
  setAbTestPercentage,
  setIsErrorDisplayEnabled,
  setIsFreemiumModeEnabled,
  setIsFullScreenModeEnabled,
  setIsPasswordEnabled,
  setIsRestartEnabled,
  setIsSettingsEnabled,
  setIsAuthedUsersOnlyEnabled,
  setIsSettingsPresetLoaded,
  setSettingsPreset,
  updateSavedSettings,
  setIsLikeButtonsEnabled,
  setIsAutoFlowEnabled,
  setIsIdleEventEnabled,
  setIsOnlineStatusEnabled,
  setIsFileSendEnabled,
  setIsEmojiSendEnabled,
  setIdleTimeoutInMinutes,
  setInputFieldPlaceholder,
  setIsLikeFeedbackEnabled,
  setIsMobileAgentEnabled,
  setIsTransferEnabled,
  setIsAddNewConversationEnabled,
} from '@states/slices/settingsSlice';
import { setCurrentSkin } from '@/states/slices/skinsSlice';
import { setOpenChatWindow } from '@/states/slices/chatbotSlice';
import { TENANT } from '@utils/constants';

function useCompanyConfig() {
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);

  // TODO: Add types for configData to match settingsSlice

  function updateConpanyConfig(configData: any) {
    const {
      AbTestPercentage,
      botName,
      buttonText,
      skinName,
      defaultVariant,
      productSelection,
      languageSelection,
      pageTitle,
      metaDescription,
      settingsPreset,
      botBackground,
      botImage,
      favicon,
      footerLogo,
      color1,
      color2,
      botNameColor,
      botMessageColor,
      userMessageColor,
      defaultOpenEnabled,
      demoModeEnabled,
      errorDisplayEnabled,
      freemiumModeEnabled,
      fullscreenModeEnabled,
      passwordEnabled,
      previewEnabled,
      restartEnabled,
      settingsEnabled,
      transferEnabled,
      addNewConversationEnabled,
      authedUsersOnlyEnabled,
      likeButtonsEnabled,
      autoFlowEnabled,
      idleEventEnabled,
      idleEventTimeoutInMinutes,
      onlineStatusEnabled,
      fileSendEnabled,
      emojiSendEnabled,
      inputFieldPlaceholder,
      likeFeedbackEnabled,
      mobileAgentEnabled,
    } = configData;

    dispatch(
      updateSavedSettings({
        botName,
        productSelection,
        languageSelection,
        botVariantSelection: defaultVariant,
        botBackground,
        demoMode: demoModeEnabled, // consolidate naming
        botImage,
        footerLogo,
        buttonText,
        color1,
        color2,
        botNameColor,
        botMessageColor,
        userMessageColor,
      })
    );
    AbTestPercentage !== null &&
      dispatch(setAbTestPercentage(AbTestPercentage));
    skinName && dispatch(setCurrentSkin(skinName));
    settingsPreset && dispatch(setSettingsPreset(settingsPreset));
    dispatch(
      setOpenChatWindow(
        defaultOpenEnabled ? 'OPEN' : previewEnabled ? 'PREVIEW' : 'CLOSED'
      )
    );
    dispatch(setIsErrorDisplayEnabled(errorDisplayEnabled));
    dispatch(setIsFreemiumModeEnabled(freemiumModeEnabled));
    dispatch(setIsFullScreenModeEnabled(fullscreenModeEnabled));
    dispatch(setIsPasswordEnabled(passwordEnabled));
    dispatch(setIsSettingsEnabled(settingsEnabled));
    dispatch(setIsRestartEnabled(restartEnabled));
    dispatch(setIsTransferEnabled(transferEnabled));
    dispatch(setIsAddNewConversationEnabled(addNewConversationEnabled));
    dispatch(setIsAutoFlowEnabled(autoFlowEnabled));
    dispatch(setIsAuthedUsersOnlyEnabled(authedUsersOnlyEnabled));
    dispatch(setIsLikeButtonsEnabled(likeButtonsEnabled));
    dispatch(setIsIdleEventEnabled(idleEventEnabled));
    dispatch(setIdleTimeoutInMinutes(idleEventTimeoutInMinutes));
    dispatch(setIsOnlineStatusEnabled(onlineStatusEnabled));
    dispatch(setIsFileSendEnabled(fileSendEnabled));
    dispatch(setIsEmojiSendEnabled(emojiSendEnabled));
    dispatch(setInputFieldPlaceholder(inputFieldPlaceholder));
    dispatch(setIsLikeFeedbackEnabled(likeFeedbackEnabled));
    dispatch(setIsMobileAgentEnabled(mobileAgentEnabled));

    if (pageTitle) {
      document.title = pageTitle;
    }
    if (favicon) {
      const link = document.querySelector(
        "link[rel~='icon']"
      ) as HTMLLinkElement;
      if (!link) {
        const newLink = document.createElement('link') as HTMLLinkElement;
        newLink.rel = 'icon';
        newLink.href = favicon;
        document.getElementsByTagName('head')[0].appendChild(newLink);
      } else {
        link.href = favicon;
      }
    }
    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        "meta[name='description']"
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.name = 'description';
        newMetaTag.content = metaDescription;
        document.getElementsByTagName('head')[0].appendChild(newMetaTag);
      }
    }
    dispatch(setIsSettingsPresetLoaded(true));
  }

  async function fetchCompanyConfigDev(): Promise<void> {
    const companyConfigDefault = {
      AbTestPercentage: 100,
      botName: 'Example Bot',
      buttonText: 'Chat with us now!',
      defaultVariant: 'default',
      productSelection: 'savings_account_english',
      languageSelection: 'english',
      skinName: 'default',
      settingsPreset: 'insait',
      pageTitle: 'Test Company Website',
      botImage: null,
      metaDescription: null,
      favicon: null,
      botBackground: null,
      footerLogo: null,
      color1: null,
      color2: null,
      botNameColor: null,
      botMessageColor: null,
      userMessageColor: null,
      defaultOpenEnabled: false,
      demoMode: true,
      errorDisplayEnabled: true,
      freemiumModeEnabled: true,
      fullscreenModeEnabled: false,
      passwordEnabled: false,
      previewEnabled: false,
      restartEnabled: true,
      settingsEnabled: true,
      authedUsersOnlyEnabled: false,
      likeButtonsEnabled: true,
      autoFlowEnabled: true,
      idleEventEnabled: false,
      idleEventTimeoutInMinutes: 10,
      onlineStatusEnabled: true,
      fileSendEnabled: true,
      emojiSendEnabled: true,
      inputFieldPlaceholder: null,
      likeFeedbackEnabled: false,
      mobileAgentEnabled: true,
      transferEnabled: true,
      addNewConversationEnabled: true,
    };
    updateConpanyConfig(companyConfigDefault);
  }

  async function fetchCompanyConfigProd(): Promise<void> {
    const companyName = TENANT ? TENANT : location.hostname.split('.')[0];

    try {
      const configData = await companyService.getCompanyConfig(companyName);
      updateConpanyConfig(configData);
    } catch (error) {
      console.error('Error while fetching company config', error);
      return fetchCompanyConfigDev();
    }
  }

  const fetchCompanyConfig =
    import.meta.env.MODE === 'development'
      ? fetchCompanyConfigDev
      : fetchCompanyConfigProd;

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchCompanyConfig();
    }
  }, []);
}
export default useCompanyConfig;
