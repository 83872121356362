import { useDispatch } from 'react-redux';
import { setShowTransferChat } from '@states/slices/chatbotSlice';
import { BiTransfer } from 'react-icons/bi';

function TransferButton() {
  const dispatch = useDispatch();

  return (
    <div
      className='transfer-button'
      role='button'
      aria-label='Transfer button'
      tabIndex={0}
      onClick={() => dispatch(setShowTransferChat(true))}
    >
      <BiTransfer />
    </div>
  );
}

export default TransferButton;
