import {
  setIsInputSent,
  setIsQuestionInput,
  setUserInput,
} from '@states/slices/chatbotSlice';
import { useDispatch, useSelector } from 'react-redux';
import './SuggestedQuestions.scss';
import { RootState } from '@/states/store';
import QuestionContent from './QuestionContent';

function SuggestedQuestions() {
  const { suggestedQuestions, frequentlyAskedQuestions } = useSelector(
    (state: RootState) => state.chatbot
  );
  const dispatch = useDispatch();

  function handleResponseButton(SuggestedQuestion: string) {
    dispatch(setUserInput(SuggestedQuestion));
    dispatch(setIsQuestionInput(true));
    dispatch(setIsInputSent(true));
  }

  return (
    <>
      {suggestedQuestions.length > 0 ? (
        <QuestionContent
          questions={suggestedQuestions}
          containerClassName={`suggested-questions-container bot-question`}
          onClick={handleResponseButton}
        />
      ) : null}
      {frequentlyAskedQuestions.length > 0 && (
        <QuestionContent
          questions={frequentlyAskedQuestions}
          containerClassName={`suggested-questions-container frequentlyAskedQuestions`}
          onClick={handleResponseButton}
        />
      )}
    </>
  );
}

export default SuggestedQuestions;
