import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversationFlowType, SettingsState } from '../../utils/types';
import AgentImage from '../../assets/images/agent.png';
import DefaultBotImage from '../../assets/images/logo-default.png';
// import LeumiBotImage from '../../skins/leumi/assets/leumi.png';
import BotBackground from '../../assets/images/bg.png';
import { changeLanguage, setHtmlLanguage, setColor } from '@utils/utils';
import { initialConversationFlow } from '@/assets/data/conversationFlow';

function getInitialLanguage() {
  const language = 'english';
  const currentLanguage = changeLanguage(language);
  return currentLanguage;
}

const initialState: SettingsState = {
  botName: 'Example Bot',
  botImage: DefaultBotImage, // TODO: Add functionality for default bot image for other skins
  agentImage: AgentImage,
  footerLogo: null,
  buttonText: "We're here to help",
  botBackground: BotBackground,
  color1: '',
  color2: '',
  botNameColor: null,
  botMessageColor: null,
  userMessageColor: null,
  productSelection: 'savings_account_english',
  botVariantSelection: 'default',
  languageSelection: 'english',
  demoMode: true,
  currentLanguage: getInitialLanguage(),
  settingsPreset: '',
  isSettingsPresetLoaded: false,
  isEmbeddedModeEnabled: false,
  isFullScreenModeEnabled: false,
  isSettingsEnabled: false,
  isPasswordEnabled: true,
  isRestartEnabled: false,
  isTransferEnabled: false,
  isAddNewConversationEnabled: false,
  isMobileModeEnabled: false,
  isErrorDisplayEnabled: true,
  isFreemiumModeEnabled: true,
  isAuthedUsersOnlyEnabled: false,
  abTestPercentage: 100,
  isLikeButtonsEnabled: true,
  isIdleEventEnabled: false,
  idleEventTimeoutInMinutes: 10,
  isOnlineStatusEnabled: true,
  isFileSendEnabled: true,
  isEmojiSendEnabled: true,
  skinName: 'default',
  conversationFlow: initialConversationFlow,
  isAutoFlowEnabled: false,
  isUserAutoResponseEnabled: false,
  botWaitSeconds: 5,
  userWaitSeconds: 2,
  inputFieldPlaceholder: '',
  isLikeFeedbackEnabled: false,
  isMobileAgentEnabled: true,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<Partial<SettingsState>>) => {
      const payload = action.payload;
      state.botName = payload.botName ?? state.botName;
      state.botImage = payload.botImage ?? state.botImage;
      state.botBackground = payload.botBackground ?? state.botBackground;
      state.footerLogo = payload.footerLogo ?? state.footerLogo;
      state.color1 = payload.color1 ?? state.color1;
      state.color2 = payload.color2 ?? state.color2;
      state.botNameColor = payload.botNameColor ?? state.botNameColor;
      state.botMessageColor = payload.botMessageColor ?? state.botMessageColor;
      state.userMessageColor =
        payload.userMessageColor ?? state.userMessageColor;
      state.buttonText = payload.buttonText ?? state.buttonText;
      state.productSelection =
        payload.productSelection ?? state.productSelection;
      state.demoMode = payload.demoMode ?? state.demoMode;
      state.isUserAutoResponseEnabled =
        payload.isUserAutoResponseEnabled ?? state.isUserAutoResponseEnabled;

      if (payload.botWaitSeconds) {
        state.botWaitSeconds = Number(payload.botWaitSeconds);
      }
      if (payload.userWaitSeconds) {
        state.userWaitSeconds = Number(payload.userWaitSeconds);
      }

      if (payload.languageSelection) {
        state.languageSelection = payload.languageSelection;
        const currentLanguage = changeLanguage(state.languageSelection);
        state.currentLanguage = currentLanguage;
        setHtmlLanguage(payload.languageSelection);
      }

      if (payload.color1) {
        setColor('primary', payload.color1);
      }
      if (payload.color2) {
        setColor('secondary', payload.color2);
      }
      if (payload.botMessageColor) {
        setColor('bot-message', payload.botMessageColor);
      }
      if (payload.userMessageColor) {
        setColor('user-message', payload.userMessageColor);
      }
    },
    updateSavedSettings: (
      state,
      action: PayloadAction<Partial<SettingsState>>
    ) => {
      const payload = action.payload;
      state.botName = payload.botName ?? state.botName;
      state.botImage = payload.botImage ?? state.botImage;
      state.botBackground = payload.botBackground ?? state.botBackground;
      state.footerLogo = payload.footerLogo ?? state.footerLogo;
      state.color1 = payload.color1 ?? state.color1;
      state.color2 = payload.color2 ?? state.color2;
      state.botNameColor = payload.botNameColor ?? state.botNameColor;
      state.botMessageColor = payload.botMessageColor ?? state.botMessageColor;
      state.userMessageColor =
        payload.userMessageColor ?? state.userMessageColor;
      state.buttonText = payload.buttonText ?? state.buttonText;
      state.productSelection =
        payload.productSelection ?? state.productSelection;
      state.botVariantSelection =
        payload.botVariantSelection ?? state.botVariantSelection;
      state.languageSelection =
        payload.languageSelection ?? state.languageSelection;
      state.demoMode = payload.demoMode ?? state.demoMode;

      if (payload.languageSelection) {
        state.languageSelection = payload.languageSelection;
        const currentLanguage = changeLanguage(state.languageSelection);
        state.currentLanguage = currentLanguage;
        setHtmlLanguage(payload.languageSelection);
      }

      setColor('primary', payload.color1 ?? state.color1);
      setColor('secondary', payload.color2 ?? state.color2);
      setColor('bot-message', payload.botMessageColor ?? state.botMessageColor);
      setColor(
        'user-message',
        payload.userMessageColor ?? state.userMessageColor
      );
    },
    setAbTestPercentage: (state, action: PayloadAction<number>) => {
      state.abTestPercentage = action.payload;
    },
    setSettingsPreset: (state, action: PayloadAction<string>) => {
      state.settingsPreset = action.payload;
    },
    setIsSettingsPresetLoaded: (state, action: PayloadAction<boolean>) => {
      state.isSettingsPresetLoaded = action.payload;
    },
    setIsEmbeddedModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isEmbeddedModeEnabled = action.payload;
    },
    setIsMobileModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isMobileModeEnabled = action.payload;
    },
    setIsSettingsEnabled: (state, action: PayloadAction<boolean>) => {
      state.isSettingsEnabled = action.payload;
    },
    setIsPasswordEnabled: (state, action: PayloadAction<boolean>) => {
      state.isPasswordEnabled = action.payload;
    },
    setIsFullScreenModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isFullScreenModeEnabled = action.payload;
      state.demoMode = action.payload && false;
    },
    setIsErrorDisplayEnabled: (state, action: PayloadAction<boolean>) => {
      state.isErrorDisplayEnabled = action.payload;
    },
    setIsFreemiumModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isFreemiumModeEnabled = action.payload;
    },
    setIsRestartEnabled: (state, action: PayloadAction<boolean>) => {
      state.isRestartEnabled = action.payload;
    },
    setIsTransferEnabled: (state, action: PayloadAction<boolean>) => {
      state.isTransferEnabled = action.payload;
    },
    setIsAddNewConversationEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAddNewConversationEnabled = action.payload;
    },
    setIsAutoFlowEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAutoFlowEnabled = action.payload;
    },
    setIsAuthedUsersOnlyEnabled: (state, action: PayloadAction<boolean>) => {
      state.isAuthedUsersOnlyEnabled = action.payload;
    },
    setIsLikeButtonsEnabled: (state, action: PayloadAction<boolean>) => {
      state.isLikeButtonsEnabled = action.payload;
    },
    setIsIdleEventEnabled: (state, action: PayloadAction<boolean>) => {
      state.isIdleEventEnabled = action.payload;
    },
    setIdleTimeoutInMinutes: (state, action: PayloadAction<number>) => {
      state.idleEventTimeoutInMinutes = action.payload;
    },
    setIsOnlineStatusEnabled: (state, action: PayloadAction<boolean>) => {
      state.isOnlineStatusEnabled = action.payload;
    },
    setIsFileSendEnabled: (state, action: PayloadAction<boolean>) => {
      state.isFileSendEnabled = action.payload;
    },
    setIsEmojiSendEnabled: (state, action: PayloadAction<boolean>) => {
      state.isEmojiSendEnabled = action.payload;
    },
    setConversationFlow: (
      state,
      action: PayloadAction<ConversationFlowType[]>
    ) => {
      state.conversationFlow = action.payload;
    },
    setInputFieldPlaceholder: (state, action: PayloadAction<string>) => {
      state.inputFieldPlaceholder =
        action.payload ?? state.currentLanguage.message;
    },
    setIsLikeFeedbackEnabled: (state, action: PayloadAction<boolean>) => {
      state.isLikeFeedbackEnabled = action.payload;
    },
    setIsMobileAgentEnabled: (state, action: PayloadAction<boolean>) => {
      state.isMobileAgentEnabled = action.payload;
    },
  },
});

export const {
  updateSettings,
  updateSavedSettings,
  setAbTestPercentage,
  setSettingsPreset,
  setIsSettingsPresetLoaded,
  setIsEmbeddedModeEnabled,
  setIsSettingsEnabled,
  setIsPasswordEnabled,
  setIsTransferEnabled,
  setIsAddNewConversationEnabled,
  setIsMobileModeEnabled,
  setIsFullScreenModeEnabled,
  setIsErrorDisplayEnabled,
  setIsFreemiumModeEnabled,
  setIsRestartEnabled,
  setIsAutoFlowEnabled,
  setIsAuthedUsersOnlyEnabled,
  setIsLikeButtonsEnabled,
  setIsIdleEventEnabled,
  setIdleTimeoutInMinutes,
  setConversationFlow,
  setIsOnlineStatusEnabled,
  setIsFileSendEnabled,
  setIsEmojiSendEnabled,
  setInputFieldPlaceholder,
  setIsLikeFeedbackEnabled,
  setIsMobileAgentEnabled,
} = settingsSlice.actions;
export default settingsSlice.reducer;
