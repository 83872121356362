import { ColorCode, TrackLinkData } from './types';
import { localisationData } from '../assets/data/allLanguages';
import analyticsService from '@/services/analyticsService';

export function getFormattedTime(Utctime?: string) {
  const currentTime = Utctime ? new Date(Utctime) : new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours > 12 ? hours - 12 : hours;
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  const formattedTime = `${formattedHours}:${formattedMinutes} ${amOrPm}`;
  return formattedTime;
}

export function gradientBackground(color1: string, color2: string) {
  return color1 && color2
    ? `linear-gradient(270deg, ${color1} 0%, ${color2})`
    : color1
    ? color1
    : color2;
}

export function getGradientBackground(colorCode: ColorCode) {
  if (colorCode.color1 && colorCode.color2) {
    return `linear-gradient(270deg, ${colorCode.color1} 0%, ${colorCode.color2})`;
  } else if (colorCode.color1) {
    return colorCode.color1;
  } else if (colorCode.color2) {
    return colorCode.color2;
  } else {
    return undefined;
  }
}

export function formatText(text: string, savedCookies: string = '', conversationId: string = '') {
  const withBold = convertToBoldText(text);
  const formattedText = withBold.map((part, _index) => {
    if (typeof part === 'string') {
      return convertToClickableLinks(part, savedCookies, conversationId);
    }
    return part;
  });
  return <>{formattedText}</>;
}

export function convertToBoldText(text: string) {
  const parts = text.split(/(\*\*[^*]+\*\*)/g);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      const boldText = part.slice(2, -2);
      return (
        <span key={index} className='bold'>
          {boldText}
        </span>
      );
    }
    return part;
  });
}

export function convertToClickableLinks(
  text: string,
  savedCookies: string = '',
  conversationId: string
) {
  const parts = text.split(
    /(\[.+?\]\(.+?\)|https?:\/\/\S+|www\.\S+|\[.+?\]\(tel:.+?\))/g
  );

  return parts.map((part, index) => {
    const markdownMatch =
      /\[([^\]]+)\]\((https?:\/\/[^\s]+|www\.[^\s]+)\)/.exec(part);
    if (markdownMatch) {
      const [, linkText, linkUrl] = markdownMatch;
      const url = linkUrl.startsWith('http') ? linkUrl : `http://${linkUrl}`;
      const urlWithCookies = savedCookies
        ? replaceCookiesPlaceholder(url, savedCookies)
        : url;

      return (
        <a
          key={index}
          href={urlWithCookies}
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => trackLinkClicked({ url: urlWithCookies, type: 'URL', conversationId })}
        >
          {linkText}
        </a>
      );
    }

    const urlMatch = part.match(/(https?:\/\/[^\s]+|www\.[^\s]+)/);
    if (urlMatch) {
      const url = urlMatch[0].startsWith('http')
        ? urlMatch[0]
        : `http://${urlMatch[0]}`;
      const urlWithCookies: string = replaceCookiesPlaceholder(
        url,
        savedCookies
      );
      return (
        <a
          key={index}
          href={urlWithCookies}
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => trackLinkClicked({ url: urlWithCookies, type: 'URL', conversationId })}
        >
          {urlMatch[0]}
        </a>
      );
    }

    const phoneMatch = part.match(/\[(.+?)\]\(tel:(.+?)\)/);
    if (phoneMatch) {
      const displayedNumber = phoneMatch[1];
      const telNumber = phoneMatch[2].replace(/[\s-()]/g, '');

      return (
        <a
          key={index}
          href={`tel:${telNumber}`}
          onClick={() =>
            trackLinkClicked({ url: `tel:${telNumber}`, type: 'PHONE', conversationId })
          }
        >
          {displayedNumber}
        </a>
      );
    }

    const emailMatch = part.match(/\[([^\]]+)\]\(mailto:([^)]+)\)/);
    if (emailMatch) {
      const [, linkText, linkUrl] = emailMatch;
      return (
        <a
          key={index}
          href={`mailto:${linkUrl}`}
          onClick={() => trackLinkClicked({ url: linkUrl, type: 'EMAIL', conversationId })}
        >
          {linkText}
        </a>
      );
    }

    return part;
  });
}

export function replaceCookiesPlaceholder(
  url: string,
  savedCookies: string = ''
) {
  const formattedCookies = savedCookies
    .split('; ')
    .reduce((acc: string[], cookie: string) => {
      const [key, value] = cookie.split('=');
      if ((!key.startsWith('cc_') && key !== 'campaignid') || !value)
        return acc;
      const newKey = key.startsWith('cc_') ? key.substring(3) : key;
      acc.push(`${encodeURIComponent(newKey)}=${encodeURIComponent(value)}`);
      return acc;
    }, [])
    .join('&');

  const urlWithCookies = url.replace('{cookies}', `${formattedCookies}`);

  return urlWithCookies;
}

export function changeLanguage(language: string) {
  const dir = language === 'hebrew' ? 'rtl' : 'ltr';
  setTextDirection('html', dir);
  setCssVariable(
    '--default-font',
    language === 'hebrew' ? 'Noto Sans Hebrew' : 'Open Sans'
  );
  return localisationData[language];
}

export function setTextDirection(selector: string, direction: string) {
  document.querySelector(selector)?.setAttribute('dir', direction);
}

export function checkAutoScroll(
  chatContainerRef: React.RefObject<HTMLDivElement>
) {
  if (chatContainerRef.current) {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }
}

export function setCssVariable(key: string, value: string) {
  document.documentElement.style.setProperty(key, value);
}

async function trackLinkClicked(data: TrackLinkData) {
  await analyticsService.saveLinkClicked(data);
}

export function getContrastingColor(
  hexColor: string,
  brightnessLevel: number = 150
) {
  // Ensure the hexColor is a valid hex code
  if (!/^#?[0-9A-Fa-f]{6}$/.test(hexColor)) {
    return;
  }

  hexColor = hexColor.replace('#', '');

  const r: number = parseInt(hexColor.substring(0, 2), 16);
  const g: number = parseInt(hexColor.substring(2, 4), 16);
  const b: number = parseInt(hexColor.substring(4, 6), 16);

  const brightness: number = (r * 299 + g * 587 + b * 114) / 1000;

  const invert: boolean = brightness > brightnessLevel;
  const contrastColor = invert ? '#000000' : '#FFFFFF';

  return { color: contrastColor, invert };
}

function getLinkColor(textColor: string): string {
  return textColor === '#FFFFFF' ? '#E0E0E0' : '#202020de';
}

export function setColor(variable_name: string, color: string | null) {
  if(color === null) return;
  setCssVariable(`--${variable_name}-color`, color);
  const contrast = getContrastingColor(color);
  if (!contrast) return;
  setCssVariable(`--${variable_name}-text`, contrast.color);

  if (variable_name === 'bot-message' || variable_name === 'user-message') {
    const linkColor = getLinkColor(contrast.color);
    setCssVariable(`--${variable_name}-link`, linkColor);
  }
  setCssVariable(
    `--${variable_name}-invert-image`,
    contrast.invert ? '100%' : '0%'
  );
  setCssVariable(
    `--${variable_name}-invert-opacity`,
    contrast.invert ? '0.5' : '1'
  );
}

export function getTwoLetterLangAbbreviation(lang: string) {
  switch (lang) {
    case 'english':
      return 'en';
    case 'hebrew':
      return 'he';
    case 'spanish':
      return 'es';
    default:
      return 'en';
  }
}

export function setHtmlLanguage(lang: string) {
  const langAbbreviation = getTwoLetterLangAbbreviation(lang);
  document.documentElement.setAttribute('lang', langAbbreviation);
}
