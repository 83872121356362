import { restartChatWithNewUser } from '@states/slices/chatbotSlice';
import { RootState } from '@/states/store';
import { useDispatch, useSelector } from 'react-redux';
import { MdRestartAlt, MdAdd } from 'react-icons/md';

function RestartButton() {
  const { currentLanguage, isAddNewConversationEnabled } = useSelector(
    (state: RootState) => state.settings
  );
  const dispatch = useDispatch();

  return (
    <div
      className='restart-button'
      onClick={() => {
        dispatch(restartChatWithNewUser(currentLanguage));
      }}
    >
      {isAddNewConversationEnabled ? <MdAdd /> : <MdRestartAlt />}
    </div>
  );
}

export default RestartButton;
